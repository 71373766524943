<template>
    <div class="columns">
        <Kategori />
        <div class="section column borderKonten">
            <div class="columns is-multiline">
                <div class="column is-4-tablet is-3-desktop" v-for="(item, i) in items" :key="i.idSub">
                    <div class="container">
                        <div id="cardContent" class="bg">
                            <img id="image" src="@/assets/bgCard.png">
                            <h2 class="judul">{{ item.namaSub }}</h2>
                            <div class="overlay">
                                <div class="bot">
                                <router-link id="seeMore" :to="`/kategori/subkategori/`+ item.idSub+ `-` + item.namaSub"><span >See More</span></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Kategori from './Kategori.vue'
import axios from "axios"

export default {
    name: "subKategori",
    components : {
        Kategori,
    },
    data(){
        return{
            categories:[],
            items:[],
            candi:[],
        };
    },
    created() {
        // this.getKategoriById();
        this.getSubKategoriById();
    },
    methods: {
        // async getKategoriById() {
        //     try {
        //         const response = await axios.get(`https://portalapi.alunalun.info/kategori/${this.$route.params.id}`);
        //         this.categories = response.data;
        //     // eslint-disable-next-line no-empty
        //     } catch (err) {}
        // },
        async getSubKategoriById() {
            console.log(this.$route.params.id);
            try {
                const response = await axios.get(`https://portalapi.alunalun.info/kategori/subkategori/${this.$route.params.id}`);
                this.items = response.data;
            // eslint-disable-next-line no-empty
            } catch (err) {}
        },
    },
}
</script>